import React from "react";
import "./Loader.css";
import icon from "../../Asset/loader icon.png";

const Loader = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center bg-[#0d0d0d]">
      <div className=" relative w-[80px] h-[80px]">
        <div className="circle"></div>
        <img
          className="w-[68px] absolute top-1.5 left-1.5"
          src={icon}
          alt="Static"
        />
      </div>
    </div>
  );
};

// const styles = {
//     container: {
//     margin: 0,
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100vh",
//     backgroundColor: "#fff",
//   },
//   loader: {
//     position: "relative",
//     width: "80px",
//     height: "80px",
//   },
//   circle: {
//     width: "100%",
//     height: "100%",
//     borderRadius: "50%",
//     border: "6px solid #0d0d0d",
//     borderRightColor: "#5ECCFF",
//     animation: "spin 1s infinite linear",
//   },
//   image: {
//     position: "absolute",
//     top: "5px",
//     left: "6px",
//     width: "70px",
//     height: "auto",
//   },
// };

export default Loader;
