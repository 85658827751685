import React from "react";
import { Typewriter } from "react-simple-typewriter";
import "./Hero.css";
import homeBg from "../../Asset/home bg.png";
import mic from "../../Asset/mic.png";
import Paraphraser from "../../Asset/Paraphraser top.png";
import Plagiarism from "../../Asset/Plagiarism top.png";
import Grammar from "../../Asset/Grammar top.png";
import homeTopBg from "../../Asset/Ellipse 116 (3).png";
import { HashLink as Link } from "react-router-hash-link";

const Home = () => {
  return (
    <div className=" 2xl:pt-[12.5rem] lg:py-[10.5rem] md:py-28 pb-28 pt-24 flex justify-center items-center md:flex-row flex-col gap-3 relative  overflow-x-clip w-full ">
      {/* <div
        style={{
          background: "radial-gradient(circle,   #5ECCFF70,#5ECCFF80)",
        }}
        className="absolute lg:w-[25rem] lg:h-[25rem] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem] left-[-5%] top-[-15%] z-0 backdrop-blur opacity-40  rounded-full shadow-[0_0_4800px_rgba(94,204,255,0.8),0_0_4800px_rgba(94,204,255,0.5),0_0_3000px_rgba(94,204,255,0.3)]"
      ></div> */}
      <div className=" block 2xl:hidden absolute z-0 md:bottom-[-20%] 2xl:right-[-5%] md:right-[-15%] right-[-25%] bottom-[-13%] 2xl:scale-[300%] md:scale-[200%] scale-[150%] ">
        <img src={homeTopBg} className="" alt="" />
      </div>

      <div className=" flex-1 z-10 2xl:text-[3rem] xl:text-[2.6rem] lg:text-[2rem] md:text-[1.5rem] text-[1.35rem]  lg:ml-10 md:ml-4 flex flex-col items-start gap-4">
        <p className=" font-medium font-clash md:text-left text-center w-full">
          <p>
            {" "}
            State-of-the-art{" "}
            <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
              Voice Typing
            </span>{" "}
          </p>
          <p>powered by ChatGPT and</p> Whisper AI
        </p>
        <div className=" md:text-left text-center md:w-full w-[85%] md:mb-0 mb-10 md:mx-0 mx-auto  2xl:text-[1.7rem] lg:text-[1.4rem] md:text-[1rem] text-[1rem] font-montserrat text-[#CECECE] h-36 ">
          <Typewriter
            words={[
              "Flawless voice typing! Simply speak, and let our AI handle the rest.",
              "Powered by the precision of Whisper API and enhanced by the intelligence of ChatGPT, your voice becomes flawlessly formatted text—every time",
            ]}
            loop={0}
            cursor
            cursorStyle="|"
            typeSpeed={50}
            deleteSpeed={30}
            delaySpeed={3000}
          />
        </div>
        <Link
          to="#footer"
          smooth
          className="hidden md:flex bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] text-white font-montserrat lg:py-4 md:py-3 py-2 lg:px-10 md:px-7 px-5 md:rounded-[10px] rounded-[7px]  lg:text-[1rem] md:text-[.8rem] text-[.65rem] font-medium outline-none"
        >
          Join our Waiting List!
        </Link>
      </div>
      <div className="flex justify-center items-center flex-1  relative ">
        <div className=" absolute z-0   ">
          <img src={homeBg} className="w-[100%]   rounded-full" alt="" />
        </div>
        <div className="  absolute 2xl:bottom-[-28%] lg:bottom-[-35%] lg:left-28 md:bottom-[-35%] md:left-16 bottom-[-22%] left-16 z-10">
          <img
            src={mic}
            className="lg:w-24 md:w-[4.5rem] w-14 animate-scaling"
            alt=""
          />
        </div>
        <div className="  absolute lg:bottom-[40%] lg:left-[8%] md:bottom-[40%] md:left-[0%] bottom-[40%] left-[2%] z-10">
          <img
            src={Paraphraser}
            className="lg:w-24 md:w-[4.5rem] w-14 animate-scaling"
            alt=""
          />
        </div>
        <div className="  absolute lg:bottom-[0%] lg:right-[6%] md:bottom-[-6%] md:right-[2%] bottom-[-6%] right-[6%] z-10">
          <img
            src={Plagiarism}
            className="lg:w-24 md:w-[4.5rem] w-14 animate-scaling"
            alt=""
          />
        </div>
        <div className="  absolute lg:top-[-25%] lg:right-[50%] md:top-[-30%] md:right-[50%] top-[-25%] right-[50%] z-10">
          <img
            src={Grammar}
            className="lg:w-24 md:w-[4.5rem] w-14 animate-scaling"
            alt=""
          />
        </div>
        <video
          className="  lg:w-[55%] md:w-[58%] w-[65%] rounded-3xl 2xl:scale-110"
          src="/wp-content/themes/child-theme/build/video/Comp_1_1_y4qajv.mp4"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          preload="metadata"
        />
      </div>
    </div>
  );
};

export default Home;
