// import Hero from "./component/hero/Hero";
import React, { Suspense, useEffect, useState } from "react";
import Navbar from "./component/Navbar/Navbar";
// import VideoSection from "./component/VideoSection/VideoSection";
import LanchingSection from "./component/LanchingSection/LanchingSection";
import Struggles from "./component/Struggles/Struggles";
import Footer from "./component/Footer/Footer";
// import ScrollAnimation from "./component/ScrollAnimation/ScrollAnimation";
import Dasboard from "./component/Dasboard/Dasboard";
import EveryOne from "./component/EveryOne/EveryOne";
import StrugglesSm from "./component/Struggles/StrugglesSm";
import Slider from "./component/EveryOne/Slider";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Home from "./component/hero/Home";
import homeTopBg from "./Asset/Ellipse 117 (3).png";
import homeTopBg1 from "./Asset/Ellipse 116 (3).png";
import Loader from "./component/Loader/Loader";
import { Element } from "react-scroll";

// import ScrollAnimationBox from "./component/ScrollAnimation/ScrollAnimationBox";
const ScrollAnimationBox = React.lazy(() =>
  import("./component/ScrollAnimation/ScrollAnimationBox")
);

// const Home = React.lazy(() => import("./component/hero/Home"));

function App() {
  useEffect(() => {
    const disableRightClick = (e) => e.preventDefault();

    const handleKeyDown = (e) => {
      if (
        e.key === "F12" ||
        (e.ctrlKey && e.shiftKey && e.key === "I") ||
        (e.ctrlKey && e.shiftKey && e.key === "C") ||
        (e.ctrlKey && e.shiftKey && e.key === "J")
      ) {
        e.preventDefault();
      }
    };

    const detectContextMenu = (e) => {
      e.preventDefault();
      console.log("Right-click is disabled");
    };

    document.addEventListener("contextmenu", detectContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    const detectDevTools = () => {
      const devToolsOpen = /./;
      devToolsOpen.toString = function () {
        console.log("Developer tools are disabled");
      };
      console.log(devToolsOpen);
    };

    detectDevTools();
    return () => {
      document.removeEventListener("contextmenu", detectContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        progressClassName="toastProgress"
        className={"custom-toast-container"}
      />
      {isLoading ? (
        <div className="w-full">
          <Loader />
        </div>
      ) : (
        <div className="bg-[#0d0d0d]">
          <div
            id="home"
            className=" min-h-screen text-white text overflow-y-auto scrollbar-hide max-w-screen-2xl mx-auto "
          >
            <div className=" overflow-hidden">
              <div className=" absolute z-0 md:top-[-30%] md:left-[-15%] top-[-35%] left-[-45%] md:scale-150 2xl:scale-[300%] ">
                <img src={homeTopBg1} className="" alt="" />
              </div>
            </div>
            <div className=" overflow-x-clip">
              <div className="2xl:block hidden absolute z-0 right-[0%] bottom-[-20%]  ">
                <img src={homeTopBg} className=" " alt="" />
              </div>
            </div>
            <Navbar />
            {/* <Suspense fallback={<div>loading...</div>}> */}
            <Element name="home">
              <Home />
            </Element>
            {/* </Suspense> */}
            {/* <Hero /> */}
            {/* <VideoSection /> */}
            <LanchingSection />
            <Struggles />
            <StrugglesSm />
            <Suspense fallback={<div>Loading...</div>}>
              <ScrollAnimationBox />
            </Suspense>
            {/* <ScrollAnimationBox /> */}
            {/* <ScrollAnimation /> */}
            <Dasboard />
            <EveryOne />
            <Slider />
            <Element name="footer">
              <Footer />
            </Element>
          </div>
        </div>
      )}
    </BrowserRouter>
  );
}

export default App;
